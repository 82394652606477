.ico {
  background: url(../../ssvcall-on-aqua.png) no-repeat left top;
  background-size: contain;
}

.start-button {
  padding: 20px 30px;
  position: absolute;
  border-radius: 5px;
  border-color: #000000;
  background-color: #000000;
  background: url(../../ssvcall-on-aqua.png) no-repeat left top;
  background-size: contain;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: right center;
  color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
  vertical-align: middle;
}

.start-button span {
  display: inline-block;
  vertical-align: middle;
}
/* background: url(../../ssvcall-on-hippa2.png) no-repeat left top; */
