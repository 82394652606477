.call {
  position: relative; /* To make it a "positioned" container so children layout works */
  height: calc(100% - 60px); /* Space for the tray */
}

.large-tiles {
  height: calc(100% - 132.5px);
  width: 80%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  align-items: center;
  /* overflow-y: scroll; */
  overflow-y: visible;
}

.small-tiles {
  height: 132.5px; /* Video height + 10px padding either side */
  display: flex;
  align-items: center;
}
